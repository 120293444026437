import { createRouter, createWebHistory } from 'vue-router';
import navList from '@/nav';

import { useUserStore } from "@/stores/user";

const routes = navList.flatMap(item => {
    if (item.list) {
        return item.list
    } else {
        return item
    }
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    document.title = to.meta.title + ' | ' + process.env.VUE_APP_DOCUMENT_TITLE;

    if (to.meta.requiresAuth && !userStore.isAuth) {
        userStore.getInfo().then(() => {
            if (userStore.isAuth) {
                next();
            } else {
                next({ path: '/login', query: { redirect: to.fullPath } })
            }
        })
    } else {
        next()
    }
  })

export default router
