<template>
    <Teleport to="body">
        <div class="notify">
            <div class="notify__item notify__close-all" v-if="notifyList.length" @click="closeAll">
                Закрыть все
            </div>
            <div class="notify__item" :class="classList(item)" v-for="item in notifyList" :key="item">
                <div class="notify__item-title">{{ item.title }}</div>

                <div class="notify__item-body">
                    {{ item.body }}
                </div>

                <div class="notify__close" @click.prevent="closeNotify(item)">
                    <svg class="notify__close" fill="#3E3F42" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-v-63dd68d4=""><path fill-rule="evenodd" clip-rule="evenodd" d="M10 9.21061L5.83702 5L5 5.84659L9.16302 10.0572L5.11302 14.1535L5.95004 15.0001L10 10.9038L14.0499 15L14.887 14.1534L10.8371 10.0572L15 5.84668L14.163 5.00009L10 9.21061Z"></path></svg>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
export default {
    name: 'NotifyLayer',
    computed: {
        notifyList() {
            return this.$notify.get()
        },
        classList() {
            return (item) => {
                const className = item.class ? item.type + ' ' + item.class : item.type;
                return className
            }
        }
    },
    methods: {
        closeNotify(item) {
            this.$notify.remove(item)
        },
        closeAll() {
            this.$notify.removeAll()
        }
    }
}
</script>

<style scoped lang="scss">
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    } 
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}
.notify {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10000;
}

.notify__item {
    width: 300px;
    padding: 10px;
    padding-right: 40px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 3px 8px rgb(0 0 0 / 10%);
    position: relative;
    margin-bottom: 10px;
    animation: 0.3s fadeIn;

    &.alert {
        background-color: var(--alert-color);
        color: var(--font-white-color);
    }

    &.info {
        background-color: var(--accent-color);
        color: var(--font-white-color);
    }

    &.removed {
        animation: 1s fadeOut;
    }
}
.notify__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px;

    .alert &,
    .info & {
        svg {
            fill: #fff;
        }
    }
}
.notify__item-title {
    font-weight: 500;
}
.notify__item-body {
    margin-top: 10px;
}

.notify__close-all {
    cursor: pointer;
    text-align: center;
}
</style>