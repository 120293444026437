import { defineStore } from 'pinia';
import { Login, Logout, MyInfo } from "@/api/login";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null
    }),
    actions: {
        login(options) {
            return Login(options)
        },
        logout() {
            return Logout().then(() => {
                this.user = null;
            })
        },
        getInfo() {
            return MyInfo().then(response => {
                this.user = response.user;
            })
        }
    },
    getters: {
        isAuth() {
            return this.user?.id;
        },
        username() {
            return this.user?.login;
        },
        title() {
            return this.user?.superpublisher_title || '';
        }
    }
  })