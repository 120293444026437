import { useNotifyStore } from "@/stores/notify";

function getBody(params) {
    return params && Object.keys(params).filter(k => params[k] !== null ).map(k => {
        if (Array.isArray(params[k]) && params[k].length > 1) {
            return params[k].map(i => `${encodeURIComponent(k)}[]=${encodeURIComponent(i)}`).join('&')
        } else {
            return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
        }
    }).join('&');
}

export default function jsonRequest(url, method = 'POST', body = null, isFormdata = false) {
    const notifyStore = useNotifyStore();

    let options = {
        method: method,
        body: body,
        credentials: 'include'
    }

    if (!isFormdata) {
        options.body = getBody(body);
        options.headers = { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" }
    }
    
    return fetch(url, options).then(response => {
        return response.json();
    }).catch((error) => {        
        notifyStore.add({
            title: 'Системная ошибка',
            body: error
        })
    });
}