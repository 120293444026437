import { defineStore } from 'pinia';

export const useNotifyStore = defineStore('notify', {
    state: () => ({
        list: [],
        duration: 5000,
        animation_time: 1000
    }),
    actions: {
        add(options) {
            options.id = options.id || new Date() * 1 + Math.random();
            options.type = options.type || 'alert';
            this.list.push(options);

            if (options.pinned) {
                return
            }

            setTimeout(() => {
                this.remove(options)
            }, options.duration || this.duration);
        },
        remove(options) {
            const item = this.list.filter(i => i.id === options.id)[0];

            if (!item) {
                return
            }

            item.class = 'removed';

            setTimeout(() => {
                this.list = this.list.filter(i => i.id !== options.id);
            }, this.animation_time);
        },
        removeAll() {
            this.list.forEach(i => {
                i.class = 'removed';
            });

            setTimeout(() => {
                this.list = [];
            }, this.animation_time);
        }
    }
  })