import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import pinia from '@/stores'
import notify from '@/plugins/notify'

import Datepicker from '@vuepic/vue-datepicker';

createApp(App)
    .component('DatePicker', Datepicker)
    .use(pinia)
    .use(notify)
    .use(router)
    .mount('#app')