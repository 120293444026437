<template>
    <component :is="layout">
        <router-view/>
        <NotifyLayer />
    </component>
</template>

<script>
    import DefaultLayout from '@/layouts/DefaultLayout.vue';
    import 'vue-multiselect/dist/vue-multiselect.css';
    import '@vuepic/vue-datepicker/dist/main.css';
    import '@/assets/scss/common.scss';

    import NotifyLayer from '@/components/NotifyLayer.vue';

    export default {
        components: {
            DefaultLayout,
            NotifyLayer
        },
        computed: {
            layout(){
                return this.$route.meta.layout || "DefaultLayout"
            }
        }
    }
</script>