import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', {
    state: () => ({
        isLoad: false,
    }),
    actions: {
        start() {
            this.isLoad = true;
        },
        stop() {
            this.isLoad = false;
        },
    }
  })