import jsonRequest from './main';

export function Login(body) {
    const REQUEST_URL = process.env.VUE_APP_REQUEST_WWW + '/api/user/login/';

    return jsonRequest(REQUEST_URL, 'POST', body);
}

export function Logout(body) {
    const REQUEST_URL = process.env.VUE_APP_REQUEST_WWW + '/api/user/logout/';

    return jsonRequest(REQUEST_URL, 'POST', body);
}

export function MyInfo(body) {
    const REQUEST_URL = process.env.VUE_APP_REQUEST_WWW + '/api/user/my_info/';

    return jsonRequest(REQUEST_URL, 'POST', body);
}