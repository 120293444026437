<template>
    <aside>
        <div class="userbar">
            <div class="userbar__name" :title="userStore.username">
                {{ userStore.username }}
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" class="logout" @click="logout">
                <path stroke="#3E3F42" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M5.625 13.125h-2.5a1.25 1.25 0 0 1-1.25-1.25v-8.75a1.25 1.25 0 0 1 1.25-1.25h2.5M10 10.625 13.125 7.5 10 4.375M13.125 7.5h-7.5"/>
            </svg>
        </div>
        <nav>
            <ul class="nav-list">
                <li class="nav-item" v-for="item in nav" :key="item" :class="{ 'm-open': item.opened }">
                    <template v-if="item.list">
                        <div class="nav-subheader" @click="setOpenNavList">
                            {{ item.title }}
                        </div>
                        <ul class="nav-list">
                            <li class="nav-item" v-for="subitem in item.list" :key="subitem">
                                <router-link :to="{ path: subitem.path }">{{ subitem.meta.title }}</router-link>
                            </li>
                        </ul>
                    </template>

                    <router-link v-else :to="{ path: item.path }">{{ item.meta.title }}</router-link>
                </li>
            </ul>
        </nav>

        <nav class="nav-bottom">
            <ul class="nav-list">
                <li class="nav-item" v-for="item in navbottom" :key="item" :class="{ 'm-open': item.opened }">
                    <template v-if="item.list">
                        <div class="nav-subheader" @click="setOpenNavList">
                            {{ item.title }}
                        </div>
                        <ul class="nav-list">
                            <li class="nav-item" v-for="subitem in item.list" :key="subitem">
                                <router-link :to="{ path: subitem.path }">{{ subitem.meta.title }}</router-link>
                            </li>
                        </ul>
                    </template>

                    <router-link v-else :to="{ path: item.path }">{{ item.meta.title }}</router-link>
                </li>
            </ul>
        </nav>
    </aside>
    <main :class="{loading: loadingStore.isLoad}">
        <slot/>
    </main>
</template>

<script>
    import navList from '@/nav';
    import { useUserStore } from "@/stores/user";
    import { useLoadingStore } from "@/stores/loading";
    import { mapStores } from 'pinia';

    export default {
        name: "DefaultLayout",
        data() {
            return {
                nav: navList.filter(item => !item.hidden && !item.bottom),
                navbottom: navList.filter(item => !item.hidden && item.bottom)
            }
        },
        computed: {
            ...mapStores(useUserStore, useLoadingStore)
        },
        methods: {
            setOpenNavList(e) {
                e.target.parentNode.classList.toggle('m-open');
            },
            logout() {
                this.userStore.logout().then(() => {
                    window.location.reload()
                })
            }
        },
        created() {
            this.$watch(
                () => this.$route.params,
                () => {
                    this.nav.forEach(item => {
                        if (item?.list?.some(i => i.path === this.$route.path)) {
                            item.opened = true;
                        }
                    })
                }
            )
        }
    }
</script>

<style lang="scss" scoped>
aside {
    width: var(--aside-width);
    min-height: 100vh;
    background-color: var(--panel-color);
    border-right: 1px solid var(--stroke-color);
    overflow: hidden;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

main {
    position: absolute;
    top: 0;
    left: var(--aside-width);
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 20px;
}

.userbar {
    padding: 10px 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        display: inline-block;
        font-size: 0;
        margin-right: -15px;
        padding: 10px;
    }
}

.userbar__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

nav {
    margin-top: 20px;

    a {
        display: block;
        min-height: 34px;
        line-height: 34px;
        padding: 0 20px;
        white-space: nowrap;

        &.router-link-exact-active {
            background-color: var(--accent-color);
            color: var(--font-white-color);
        }
    }
}

.nav-item {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    .nav-list {
        display: none;
    }

    &.m-open {
        border-top: 1px solid var(--stroke-color);
        border-bottom: 1px solid var(--stroke-color);
        padding-bottom: 0;

        .nav-list {
            display: block;
            
            .nav-item {
                a {
                    padding-left: 30px;
                }
            }
        }
    }

    &.m-open + &.m-open {
        border-top-color: transparent;
    }
}

.nav-list {
    padding: 0;
    list-style-type: none;
}

.nav-subheader {
    min-height: 34px;
    padding: 0 30px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &::after {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 4L6 7.38341H12L9 4ZM9 13.9998L12 10.6665H6L9 13.9998Z' fill='%233E3F42'/%3E%3C/svg%3E%0A");
        width: 18px;
        height: 18px;
        margin-right: -18px;
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
    }

    .m-open > & {
        font-weight: bold;

        &::after {
            transform: rotate(180deg);
        }
    }
}

.nav-bottom {
    margin-top: auto;
}

.logout {
    cursor: pointer;
}
</style>