import { useNotifyStore } from "@/stores/notify";

export default {
    install: (app) => {
        const notifyStore = useNotifyStore();

        app.config.globalProperties.$notify = {
            add: (options) => {                
                notifyStore.add(options)
            },
            remove: (options) => {
                notifyStore.remove(options)
            },
            removeAll: () => {
                notifyStore.removeAll()
            },
            get: () => {
                return notifyStore.list
            }
        };
    }
  }