export default [
    { 
        path: "/",
        name: "main",
        hidden: true,
        redirect: "/news",
        meta: { title: "Главная" }
    },
    {
        path: "/login",
        name: "login",
        hidden: true,
        meta: { layout: "EmptyLayout", title: "Авторизация" },
        component: () => import(/* webpackChunktitle: "login" */ "@/views/LoginView.vue"),
    },
    {
        path: "/logout",
        name: "logout",
        hidden: true,
        meta: { layout: "EmptyLayout", title: "Выход" },
        component: () => import(/* webpackChunktitle: "logout" */ "@/views/LoginView.vue"),
    },
    {
        title: "Лента новостей",
        list: [
            {
                path: "/news",
                name: "news",
                meta: { requiresAuth: true, title: "Список" },
                component: () => import( /* webpackChunktitle: "news" */ "@/views/News/ListView.vue" )
            },
            {
                path: "/news/block",
                name: "news/block",
                meta: { requiresAuth: true, title: "Заблокированные" },
                component: () => import( /* webpackChunktitle: "news" */ "@/views/News/HiddenView.vue" )
            },
            // {
            //     path: "/news/duplicate",
            //     name: "news/duplicate",
            //     meta: { requiresAuth: true, title: "Дубликаты" },
            //     component: () => import( /* webpackChunktitle: "news" */ "@/views/News/DuplicateView.vue" )
            // }
        ]
    },
    {
        title: "Статистика",
        list: [
            {
                path: "/stat",
                name: "stat",
                meta: { requiresAuth: true, title: "Издание" },
                component: () => import( /* webpackChunktitle: "stat" */ "@/views/Stat/IndexView.vue" )
            },
            {
                path: "/stat/news",
                name: "stat/news",
                meta: { requiresAuth: true, title: "Новости" },
                component: () => import( /* webpackChunktitle: "stat" */ "@/views/Stat/NewsView.vue" )
            }
        ]
    },
    {
        path: "/profile",
        name: "profile",
        meta: { requiresAuth: true, title: "Профиль" },
        component: () => import( /* webpackChunktitle: "profile" */ "@/views/ProfileView.vue" ),
    },
    {
        title: "Ограничения",
        list: [
            {
                path: "/settings/stop",
                name: "settings/stop",
                meta: { requiresAuth: true, title: "Стоп-слова" },
                component: () => import( /* webpackChunktitle: "settings" */ "@/views/Settings/StopView.vue" ),
            },
            {
                path: "/settings/media",
                name: "settings/media",
                meta: { requiresAuth: true, title: "СМИ" },
                component: () => import( /* webpackChunktitle: "settings" */ "@/views/Settings/MediaView.vue" ),
            }
        ],
    },
    {
        title: "Информер",
        list: [
            {
                path: "/informer/list",
                name: "informer/list",
                meta: { requiresAuth: true, title: "Список" },
                component: () => import( /* webpackChunktitle: "informer" */ "@/views/Informer/ListView.vue" ),
            }
        ],
    },
    // {
    //     path: "/informer",
    //     name: "informer",
    //     meta: { requiresAuth: true, title: "Информер" },
    //     component: () => import(/* webpackChunktitle: "informer" */ "@/views/InformerView.vue"),   
    // },
    {
        path: "/feedback",
        name: "feedback",
        bottom: true,
        meta: { requiresAuth: true, title: "Обратная связь" },
        component: () => import(/* webpackChunktitle: "feedback" */ "@/views/FeedbackView.vue"),   
    }
    // {
    //     path: "/info",
    //     name: "info",
    //     bottom: true,
    //     meta: { title: "Информация для СМИ" },
    //     component: () => import(/* webpackChunktitle: "info" */ "@/views/InfoView.vue"),   
    // },
    // {
    //     path: "/policy",
    //     name: "policy",
    //     bottom: true,
    //     meta: { title: "Политика конфиденциальности" },
    //     component: () => import(/* webpackChunktitle: "policy" */ "@/views/PolicyView.vue"),   
    // },
    // {
    //     path: "/faq",
    //     name: "faq",
    //     bottom: true,
    //     meta: { title: "FAQ" },
    //     component: () => import(/* webpackChunktitle: "faq" */ "@/views/FAQView.vue"),   
    // }
];
